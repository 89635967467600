import { useCallback, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import UseLogout from "../../components/UseLogout/UseLogout";
import usePaginationWrapper from "../../components/PaginationWrapper/usePaginationWrapper";
import Header from "../../components/Header/Header"
import PaginationWrapper from "../../components/PaginationWrapper/PaginationWrapper";
import { fetchRidesWithStatus, razorpayTransaction, rideComplete } from "../../common/services/api.service";
import { RIDE_STATUS, TRANSACTION_MESSAGE } from "../../common/constants";
import './OngoingRides.scss'

const OngoingRides = () => {
    const { handleLogout } = UseLogout();
    const [rides, setRides] = useState([])
    const { currentPage, setCurrentPage, limit, totalCount, setTotalCount } = usePaginationWrapper();

    const getOngoingRides = useCallback(async () => {
        try {
            const response = await fetchRidesWithStatus({ status: RIDE_STATUS.ONGOING, page: currentPage, limit })
            setRides(response?.data || [])
            setTotalCount(response?.totalCount)
        } catch (err) {
            toast.error(err?.message || 'Unable to fetch drivers')
        }
    }, [currentPage, limit, setTotalCount])


    const handleRideComplete = async ({ id, driverId }) => {
        try {
            toast.loading('Updating...')
            //todo - enable when deduction starts
            // const response = await razorpayTransaction({
            //     driverId,
            //     rideId: id,
            //     transactionType: TRANSACTION_MESSAGE.COMMISSION,
            // });
            // const { fees, totalAmount } = response?.data;
            // const success = response?.success;
            // if (!success) {
            //     throw new Error('Failed to complete payment')
            // }
            // const rideResponse = await rideComplete({ id, fees: Math.round(fees).toFixed(2), totalAmount: Math.round(totalAmount).toFixed(2) });
            const rideResponse = await rideComplete({ id, driverId });
            const data = rideResponse?.data;
            if (data) {
                setRides((prevRides) =>
                    prevRides.filter((ride) => ride?._id !== id)
                );
                toast.success(data || "Ride completed successfully");
            }
        } catch (error) {
            toast.error("Failed to complete ride");
        } finally {
            toast.dismiss()
        }
    };

    useEffect(() => {
        getOngoingRides()
    }, [getOngoingRides])

    return (
        <main className="ongoingRides-container">
            <Toaster />
            <Header title={'Ongoing Rides'} handleLogout={handleLogout} />
            <section>
                <table className='ride-details-table'>
                    <thead>
                        <tr>
                            <th>Rider Name</th>
                            <th>Rider Phone Number</th>
                            <th>Driver Name</th>
                            <th>Driver Phone Number</th>
                            <th>Pickup</th>
                            <th>Drop</th>
                            <th>Vehicle</th>
                            <th>Vehicle Number</th>
                            <th>Price</th>
                            <th>Date time</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rides?.length ? rides?.map((ride, index) => (
                                <tr key={`ongoingRide-${index}`}>
                                    <td>{ride?.riderId?.name}</td>
                                    <td>{ride?.riderId?.phoneNumber}</td>
                                    <td>{ride?.driverId?.name}</td>
                                    <td>{ride?.driverId?.phoneNumber}</td>
                                    <td>{ride?.pickup}</td>
                                    <td>{ride?.drop}</td>
                                    <td>{ride?.vehicle}</td>
                                    <td>{ride?.vehicleNumber}</td>
                                    <td>{ride?.price}₹</td>
                                    <td>{(ride?.dateTime)}</td>
                                    <td> <button onClick={() => handleRideComplete({ id: ride._id, driverId: ride?.driverId?._id })}> End </button> </td>
                                </tr>
                            )) : <p> No ongoing rides found</p>

                        }
                    </tbody>
                </table>
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    totalCount={totalCount}
                />
            </section>
        </main>
    )
}

export default OngoingRides