export const ADMIN_COOKIE = 'admintoken';

export const REDIRECTION_ROUTES = {
    DRIVERS: '/drivers',
    LOGIN: '/',
    RIDES: '/rides',
    RIDERS: '/riders',
    ONBOARDING: '/onboarding',
    ONGOING_RIDES: '/ongoing-rides',
    COMPLETED_RIDES: '/completed-rides',
    CANCELLED_RIDES: '/cancelled-rides',
}

export const RIDE_STATUS = {
    SCHEDULED: 1,
    ONGOING: 2,
    COMPLETED: 3,
    CANCELLEDBYRIDER: 4,
    CANCELLEDBYDRIVER: 5,
    CANCELLEDBYADMIN: 6
};

export const DRIVER_STATUS = {
    1: 'INACTIVE',
    2: 'ACTIVE',
    3: 'BLOCKED'
}

export const sideBarRoutes = [
    {
        index: 0,
        title: 'Ride Requests',
        route: REDIRECTION_ROUTES.RIDES
    },
    {
        index: 1,
        title: 'Ongoing Rides',
        route: REDIRECTION_ROUTES.ONGOING_RIDES
    },
    {
        index: 2,
        title: 'Drivers',
        route: REDIRECTION_ROUTES.DRIVERS
    },
    {
        index: 3,
        title: 'Riders',
        route: REDIRECTION_ROUTES.RIDERS
    },
    {
        index: 4,
        title: 'Onboarding',
        route: REDIRECTION_ROUTES.ONBOARDING
    },
    {
        index: 5,
        title: 'Completed Rides',
        route: REDIRECTION_ROUTES.COMPLETED_RIDES
    },
    {
        index: 6,
        title: 'Cancelled Rides',
        route: REDIRECTION_ROUTES.CANCELLED_RIDES
    }
];

export const prevDate = 'Thu, 01 Jan 1970 00:00:00 UTC';

export const TRANSACTION_MESSAGE = {
    ADDED: 'RECHARGED',
    COMMISSION: 'COMMISSION',
}