import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import "./CustomSideBar.css";

const CustomSideBar = ({ routes }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <img src="/menu.svg" alt="menu" />
      </button>
      <Sidebar className={`custom-sidebar ${isSidebarOpen ? "open" : "close"}`}>
        <Menu
          menuItemStyles={{
            button: ({ level, active }) => {
              if (level === 0)
                return {
                  backgroundColor: active ? "var(--clr-grey-200)" : undefined,
                };
            },
          }}
        >
          {routes.map(({ title, route }, index) => (
            <section key={`route-${index}`}>
              <MenuItem
                active={location.pathname === route}
                component={<Link to={route} />}
              >
                {title}
              </MenuItem>
            </section>
          ))}
        </Menu>
      </Sidebar>
    </>
  );
};

export default CustomSideBar;
