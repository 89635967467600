import "./CustomButton.css";

const CustomButton = ({ color, onClick, text }) => {
    return (
        <button className={`custom-button btn-${color}`} onClick={onClick}>
            {text}
        </button>
    );
}

export default CustomButton;