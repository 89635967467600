import './CustomDriverSelect.css'

const CustomDriverSelect = ({drivers, selectedDriverId, handleChange}) => {
    
    return (
        <select className='driver-select' name="driver-info" value={selectedDriverId} onChange={handleChange}>
            <option value={null} > Select Driver</option>
            {drivers.map(driver => {
                const { id, vehicleNumber } = driver;
                return (
                    <option key={`available-drivers-${id}`} value={id}>
                        {vehicleNumber}
                    </option>
                );
            })}
        </select>
    )
}

export default CustomDriverSelect
