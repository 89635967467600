import { memo } from "react";
import "./CustomInput.scss";

const CustomInput = ({ name,onChange,placeholder,type,value }) => {
  return (
    <input
      className="custom-input"
      placeholder={placeholder}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
    />
  );
}

export default memo(CustomInput);
