import './CustomIconButton.css'

const CustomIconButton = ({iconUrl, alt, handleClick, extraStyles}) => {
    return(
        <button onClick={handleClick} className={`icon-button ${extraStyles}`}>
            <img src={iconUrl} alt={alt} />
        </button>
    )
}

export default CustomIconButton