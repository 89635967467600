import { useEffect, useState } from "react";
import { fetchOnboardDrivers } from "../../common/services/api.service";
import Header from "../../components/Header/Header";
import OnboardDriverTemplate from "../../components/OnboardDriverTemplate/OnboardDriverTemplate";
import UseLogout from "../../components/UseLogout/UseLogout";

const Onboarding = () => {
    const [onboardDrivers, setOnboardDrivers] = useState([]);
    const { handleLogout } = UseLogout();

    const getDrivers = async () => {
        const { data } = await fetchOnboardDrivers()
        setOnboardDrivers(data || [])
    }

    const removeDrivers = (id) => {
        const updatedDrivers = onboardDrivers.filter(({ _id }) => _id !== id)
        setOnboardDrivers([...updatedDrivers])
    }

    useEffect(() => {
        getDrivers()
    }, [])


    return (
        <main>
            <Header title={'Onboarding'} handleLogout={handleLogout} />
            {
                onboardDrivers?.length ?
                    onboardDrivers.map(({ adminApproval, driver_documents: { documents }, email, name, _id, phoneNumber, vehicleNumber, vehicleCategory }, index) => (
                        <OnboardDriverTemplate
                            adminApproval={adminApproval}
                            documents={documents}
                            email={email}
                            id={_id}
                            key={`onboardDriver-${index}`}
                            name={name}
                            phoneNumber={phoneNumber}
                            removeDrivers={removeDrivers}
                            vehicleNumber={vehicleNumber}
                            vehicleCategory={vehicleCategory}
                        />
                    )) :
                    <p> No Onboarded Drivers found! </p>
            }
        </main>
    )
}

export default Onboarding;