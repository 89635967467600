import { useEffect } from 'react';
import { getCookie } from '../../common/helpers';
import { ADMIN_COOKIE, sideBarRoutes } from '../../common/constants';
import CustomSideBar from '../CustomSideBar/CustomSideBar';

const PrivateRoute = ({ Component }) => {

  useEffect(() => {
    const token = getCookie(ADMIN_COOKIE);
    if (!token) {
      window.location.href = '/';
      return;
    }
  }, []);

  return (
    <main className="flex">
      <CustomSideBar routes={sideBarRoutes} />
      <section className="w100">
        <Component />
      </section>
    </main>
  )
};

export default PrivateRoute;
