import { useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import { toast, Toaster } from 'react-hot-toast';
import CustomIconButton from "../CustomIconButton/CustomIconButton";
import { fetchVehiclesLabelKeyObject, updateOnboardDriverStatus } from "../../common/services/api.service";
import './OnboardDriverTemplate.scss'

const OnboardDriverTemplate = ({ documents, email, id, name, phoneNumber, removeDrivers, vehicleNumber, vehicleCategory }) => {
    const [vehicleCategoryObject, setVehicleCategoryObject] = useState();
    const [rejectMessage, setRejectMessage] = useState('');
    const [hasReject, setHasReject] = useState(false)

    const toggleReject = () => {
        setHasReject(prev => !prev)
    }

    const handleApprove = async () => {
        try {
            const payload = {
                approve: true,
                id
            }
            await updateOnboardDriverStatus(payload)
            removeDrivers(id)
        } catch (err) {
            toast.error(err?.message || 'Unable to approve something went wrong')
        }
    }

    const handleReject = async () => {
        try {
            const payload = {
                rejectionMessage: rejectMessage,
                id
            }
            await updateOnboardDriverStatus(payload)
            removeDrivers(id)
            setHasReject(prev => !prev)
        } catch (err) {
            toast.error(err?.message || 'Unable to reject something went wrong')
        }
    }

    
    const getVehicleCatgeory = async () => {
        const vehicleCategories = await fetchVehiclesLabelKeyObject();
        setVehicleCategoryObject(vehicleCategories?.data);
    }

    useEffect(() => {
        getVehicleCatgeory();
    }, [])

    return (
        <section className="onboard-template-container">
            <Toaster />
            <p>name - {name}</p>
            {email && <p>email - {email}</p>}
            <p>phoneNumber - {phoneNumber}</p>
            <p>vehicleNumber - {vehicleNumber}</p>
            {vehicleCategoryObject && (<p>vehicleCategory - {vehicleCategoryObject[vehicleCategory]}</p>)}
            <article className="documents-styles">
                {Object.entries(documents).map(([key, value], index) => (
                    <article key={`driverDoc-${index}`} className="documents-styles-item">
                        <p> <u>{key}</u> </p>
                        <ModalImage small={value} large={value} alt={value} className="image-styles" />
                    </article>
                ))}
            </article>
            <CustomIconButton iconUrl={'/tick.svg'} alt={'approve'} handleClick={handleApprove} />
            <CustomIconButton iconUrl={'/cancel.svg'} alt={'disapprove'} handleClick={toggleReject} />
            {hasReject && (
                <>
                    <input placeholder="Reason for rejection" onChange={(e) => setRejectMessage(e.target.value)} />
                    <button onClick={handleReject} className="rejection-submit-button"> submit </button>
                </>
            )}
        </section>
    )
}

export default OnboardDriverTemplate;