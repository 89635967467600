import Pagination from "../Pagination/Pagination";

const PaginationWrapper = ({ currentPage, setCurrentPage, limit, totalCount }) => {

    return (
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={limit}
            onPageChange={page => setCurrentPage(page)}
        />
    )
}

export default PaginationWrapper;