import React, { useCallback, useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import UseLogout from '../../components/UseLogout/UseLogout';
import usePaginationWrapper from '../../components/PaginationWrapper/usePaginationWrapper';
import Header from '../../components/Header/Header';
import RideDetails from '../../components/RideDetails/RideDetails';
import CustomIconButton from '../../components/CustomIconButton/CustomIconButton';
import { fetchRidesWithStatus, fetchAvailableDrivers } from '../../common/services/api.service';
import PaginationWrapper from '../../components/PaginationWrapper/PaginationWrapper';
import { RIDE_STATUS } from '../../common/constants';
import './RideRequest.scss';

const RideRequest = () => {
    const { handleLogout } = UseLogout();
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const {currentPage, setCurrentPage, limit, totalCount, setTotalCount} = usePaginationWrapper();
    const [rides, setRides] = useState([]);

    const removeRide = (id) => {
        const updatedRides = rides.filter(({ _id }) => _id !== id);
        setRides([...updatedRides]);
    }

    const removeDriver = (id) => {
        const updatedDrivers = availableDrivers.filter((driver) => driver.id !== id);
        setAvailableDrivers([...updatedDrivers]);
    }

    const getAvailableDrivers = useCallback(async () => {
        try {
            toast.loading('Refreshing drivers')
            const { data = [] } = await fetchAvailableDrivers();
            const drivers = data.map(driver => ({
                id: driver?._id,
                name: driver?.name,
                vehicleNumber: driver?.vehicleNumber,
            }));
            toast.dismiss()
            setAvailableDrivers(drivers || []);
        } catch (error) {
            toast.error(error?.message || 'Failed to fetch available drivers');
        }
    },[])

    const getRideRequestsData = useCallback(async () => {
        try {
            const { data = null, totalCount } = await fetchRidesWithStatus({ status: RIDE_STATUS.SCHEDULED, page: currentPage, limit});
            setTotalCount(totalCount)
            setRides(data || []);
        } catch (error) {
            toast.error(error?.message || 'Failed to fetch ride requests');
        }
    },[currentPage, limit, setTotalCount])

    useEffect(() => {
        getRideRequestsData();
        getAvailableDrivers();
    }, [currentPage, getAvailableDrivers, getRideRequestsData]);

    return (
        <section className='ride-request-container'>
            <Toaster />
            <Header title={'All Ride Requests'} handleLogout={handleLogout} />
            <table className='ride-details-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Pickup</th>
                        <th>Drop</th>
                        <th>Vehicle</th>
                        <th>Price</th>
                        <th>Date time</th>
                        <th>
                            Driver
                            <CustomIconButton iconUrl={'/refresh.svg'} alt={'refresh'} extraStyles={'refresh-button-styles'} handleClick={getAvailableDrivers} />
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rides?.length ?
                            rides?.map((ride, index) => (
                                <RideDetails
                                    key={`ridedetails-${index}`}
                                    ride={ride}
                                    availableDrivers={availableDrivers}
                                    removeRide={removeRide}
                                    removeDriver={removeDriver}
                                />
                            ))
                            :
                            <p>No ride requests found!</p>
                    }
                </tbody>
            </table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                totalCount={totalCount} 
            />
        </section>
    );
}

export default RideRequest;
