import { useNavigate } from 'react-router-dom';
import { ADMIN_COOKIE } from '../../common/constants';
import { prevDate } from '../../common/constants';
import { REDIRECTION_ROUTES } from '../../common/constants';

const UseLogout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        document.cookie = `${ADMIN_COOKIE}=; expires=${prevDate}; path=/;`;
        navigate(REDIRECTION_ROUTES.LOGIN);
    }

    return { handleLogout };
};

export default UseLogout;