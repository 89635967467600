import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import CompletedRides from "./pages/CompletedRides/CompletedRides";
import CancelledRides from "./pages/CancelledRides/CancelledRides";
import LoginPage from './pages/LoginPage/LoginPage';
import RideRequest from './pages/RideRequest/RideRequest';
import Onboarding from "./pages/Onboarding/Onboarding";
import OngoingRides from "./pages/OngoingRides/OngoingRides";
import Riders from "./pages/Riders/Riders";
import Drivers from "./pages/Drivers/Drivers";
import { REDIRECTION_ROUTES } from "./common/constants";
import './styles/globals.scss';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={REDIRECTION_ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={REDIRECTION_ROUTES.DRIVERS} element={<PrivateRoute Component={Drivers} />} />
        <Route path={REDIRECTION_ROUTES.RIDERS} element={<PrivateRoute Component={Riders} />} />
        <Route path={REDIRECTION_ROUTES.RIDES} element={<PrivateRoute Component={RideRequest} />} />
        <Route path={REDIRECTION_ROUTES.ONBOARDING} element={<PrivateRoute Component={Onboarding} />} />
        <Route path={REDIRECTION_ROUTES.ONGOING_RIDES} element={<PrivateRoute Component={OngoingRides} />} />
        <Route path={REDIRECTION_ROUTES.COMPLETED_RIDES} element={<PrivateRoute Component={CompletedRides} />} />
        <Route path={REDIRECTION_ROUTES.CANCELLED_RIDES} element={<PrivateRoute Component={CancelledRides} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;