import React from 'react';
import CompletedCancelledRides from '../../components/CompletedCancelledRides/CompletedCancelledRides';
import { RIDE_STATUS } from '../../common/constants';
const CancelledRides = () => {
    return (
        <CompletedCancelledRides title="Completed Rides" type={RIDE_STATUS.COMPLETED} />
    );
}

export default CancelledRides;
