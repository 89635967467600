import { useCallback, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import Header from "../../components/Header/Header"
import UseLogout from "../../components/UseLogout/UseLogout";
import usePaginationWrapper from "../../components/PaginationWrapper/usePaginationWrapper";
import PaginationWrapper from "../../components/PaginationWrapper/PaginationWrapper";
import { fetchRiders } from "../../common/services/api.service";
import './Riders.scss'

const Riders = () => {
    const { handleLogout } = UseLogout();
    const [riders, setRiders] = useState([])
    const { currentPage, setCurrentPage, limit, totalCount, setTotalCount } = usePaginationWrapper();

    const getRiders = useCallback( async () => {
        try {
            const response = await fetchRiders({page: currentPage, limit });
            setRiders(response?.data || []);
            setTotalCount(response?.totalCount);
        } catch (err) {
            toast.error(err?.message || 'Unable to fetch riders')
        }
    },[currentPage, limit, setTotalCount])

    useEffect(() => {
        getRiders()
    }, [getRiders])

    return (
        <main className="rider-container">
            <Toaster />
            <Header title={'Riders'} handleLogout={handleLogout} />
            <section>
                <table className='ride-details-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            riders?.length ? riders?.map((rider, index) => (
                                <tr key={`rider-detail-${index}`}>
                                    <td>{rider?.name}</td>
                                    <td>{rider?.phoneNumber}</td>
                                    <td>{rider?.email}</td>
                                </tr>
                            )) : <p> No Riders found</p>

                        }
                    </tbody>
                </table>
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    totalCount={totalCount}
                />
            </section>
        </main>
    )
}

export default Riders