import { API_URL } from "../config";
import { handleAPIResponse, handleRequestOptions } from "../helpers";

export const adminLogin = async (payload) => {
    const response = await fetch(`${API_URL}/auth/adminlogin`, handleRequestOptions({ payload, method: 'POST' }));
    return handleAPIResponse(response);
}

export const fetchRidesWithStatus = async (payload) => {
    const { page, limit, status } = payload;
    const response = await fetch(`${API_URL}/admin/fetchRidesWithStatus?status=${status}&page=${page}&limit=${limit}`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const fetchAvailableDrivers = async (payload) => {
    const response = await fetch(`${API_URL}/admin/fetchAvailableDrivers`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const fetchCompletedCancelledRides = async (payload) => {
    const { limit, type, page } = payload;
    const response = await fetch(`${API_URL}/admin/fetchCompletedCancelledRides?limit=${limit}&page=${page}&type=${type}`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const fetchDrivers = async (payload) => {
    const { page, limit } = payload;
    const response = await fetch(`${API_URL}/driver/fetchDrivers?page=${page}&limit=${limit}`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const fetchRiders = async (payload) => {
    const { page, limit } = payload;
    const response = await fetch(`${API_URL}/rider/fetchRiders?page=${page}&limit=${limit}`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const fetchVehiclesLabelKeyObject = async () => {
    const response = await fetch(`${API_URL}/config/fetchVehiclesLabelKeyObject`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
};

export const fetchWalletBalance = async (payload) => {
    const { driverId } = payload;
    const response = await fetch(`${API_URL}/driver/fetchWalletBalance?driverId=${driverId}`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const updateRideStatusAndDriverAvailability = async (payload) => {
    const response = await fetch(`${API_URL}/admin/updateRideStatusAndDriverAvailability`, handleRequestOptions({ payload, method: 'PUT' }));
    return handleAPIResponse(response);
}

export const fetchOnboardDrivers = async (payload) => {
    const response = await fetch(`${API_URL}/admin/fetchOnboardDrivers`, handleRequestOptions({ method: 'GET' }));
    return handleAPIResponse(response);
}

export const updateOnboardDriverStatus = async (payload) => {
    const response = await fetch(`${API_URL}/admin/updateOnboardDriverStatus`, handleRequestOptions({ payload, method: 'POST' }));
    return handleAPIResponse(response);
}

export const razorpayTransaction = async (payload) => {
    const response = await fetch(`${API_URL}/driver/razorpayTransaction`, handleRequestOptions({ payload, method: 'POST' }));
    return handleAPIResponse(response);
}

export const rideComplete = async (payload) => {
    const response = await fetch(`${API_URL}/driver/rideComplete`, handleRequestOptions({ payload, method: 'PUT' }));
    return handleAPIResponse(response);
}