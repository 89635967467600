import React, { useCallback, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { adminLogin } from '../../common/services/api.service';
import CustomInput from '../../components/CustomInput/CustomInput'
import CustomButton from '../../components/CustomButton/CustomButton'
import { handleSetCookie } from '../../common/helpers';
import { REDIRECTION_ROUTES } from '../../common/constants';
import './LoginPage.css';

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailChange = useCallback((event) => {
        setEmail(event.target.value);
    }, []);

    const handlePasswordChange = useCallback((event) => {
        setPassword(event.target.value);
    }, []);

    const handleLogin = async () => {
        try {
            const response = await adminLogin({ email, password });
            if (response?.data) {
                const { token } = response.data;
                handleSetCookie(token);
                navigate(REDIRECTION_ROUTES.RIDES);
                toast.success('Login Successful');
            } else {
                toast.error('Login Failed');
            }
        } catch (error) {
            toast.error(error?.data || 'Login Failed');
        }
    }

    return (
        <section className='login-page-container'>
            <Toaster />
            <div className='login-page'>
                <h1> <u>Admin Login</u> </h1>
                <CustomInput
                    name='email'
                    onChange={handleEmailChange}
                    placeholder='Enter email'
                    type='email'
                    value={email}
                />
                <CustomInput
                    name='password'
                    onChange={handlePasswordChange}
                    placeholder='Enter password'
                    type='password'
                    value={password}
                />
                <CustomButton color={"black"} onClick={handleLogin} text='Login' />
            </div>
        </section>
    );
}

export default LoginPage;