import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { fetchVehiclesLabelKeyObject, updateRideStatusAndDriverAvailability } from '../../common/services/api.service';
import { RIDE_STATUS } from '../../common/constants';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import CustomDriverSelect from '../CustomDriverSelect/CustomDriverSelect';

const RideDetails = ({ availableDrivers, removeDriver, removeRide, ride }) => {
    const [selectedDriver, setSelectedDriver] = useState({ driverId: '', vehicleNumber: '' })
    const [vehicleCategoryObject, setVehicleCategoryObject] = useState({});
    const { _id, pickup, riderId, drop, vehicle, price, dateTime } = ride;
    const { name: riderName = null, phoneNumber: riderPhoneNumber = null } = riderId || {};

    const handleApprove = async () => {
        try {
            if (!selectedDriver.driverId) {
                throw Error('Please select Driver')
            }
            const response = await updateRideStatusAndDriverAvailability({ rideId: _id, status: RIDE_STATUS.ONGOING, driverId: selectedDriver.driverId, vehicleNumber: selectedDriver.vehicleNumber });
            removeRide(_id);
            removeDriver(selectedDriver.driverId);
            toast.success(response?.data || "Ride approved");
        } catch (error) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const handleDisapprove = async () => {
        try {
            const response = await updateRideStatusAndDriverAvailability({ rideId: _id, status: RIDE_STATUS.CANCELLEDBYADMIN, driverId: selectedDriver.driverId });
            removeRide(_id);
            toast.success(response?.data || "Ride disapproved");
        } catch (error) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const handleDriverChange = (e) => {
        setSelectedDriver({ driverId: e.target.value, vehicleNumber: e.target.selectedOptions[0].text })
    };

    const getVehicleCatgeory = async () => {
        const vehicleCategories = await fetchVehiclesLabelKeyObject();
        setVehicleCategoryObject(vehicleCategories?.data);
    }

    useEffect(() => {
        getVehicleCatgeory();
    }, [])

    return (
        <>
            <Toaster />
            <tr>
                <td>{riderName}</td>
                <td>{riderPhoneNumber}</td>
                <td>{pickup}</td>
                <td>{drop}</td>
                <td>{vehicleCategoryObject ? vehicleCategoryObject[vehicle] : 'loading...'}</td>
                <td>{price}₹</td>
                <td>{dateTime}</td>
                <td>
                    <CustomDriverSelect
                        drivers={availableDrivers}
                        selectedDriverId={selectedDriver.driverId}
                        handleChange={handleDriverChange}
                    />
                </td>
                <td>
                    <CustomIconButton iconUrl={'/tick.svg'} alt={'approve'} handleClick={handleApprove} />
                    <CustomIconButton iconUrl={'/cancel.svg'} alt={'disapprove'} handleClick={handleDisapprove} />
                </td>
            </tr>
        </>
    );
};

export default RideDetails;
