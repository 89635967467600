import CustomIconButton from '../CustomIconButton/CustomIconButton';
import './Header.css'

const Header = ({ title, handleLogout }) => {
    return (
        <header className='header-container'>
            {handleLogout && (
                    <div className='topRight'>
                        <CustomIconButton iconUrl={"/logout.svg"} alt='logout' handleClick={handleLogout} />
                    </div>
                )}
            <p className='header-title'>{title}</p>
        </header>

    )
}

export default Header;