import React from 'react';
import CompletedCancelledRides from '../../components/CompletedCancelledRides/CompletedCancelledRides';
import { RIDE_STATUS } from '../../common/constants';

const CancelledRides = () => {  
    return (
        <CompletedCancelledRides title="Cancelled Rides" type={RIDE_STATUS.CANCELLEDBYADMIN}/>
    );
}

export default CancelledRides;
