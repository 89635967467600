import { ADMIN_COOKIE } from "./constants";

export const handleRequestOptions = ({ payload, method }) => {
    const token = getCookie('admintoken')
    const requestOptions = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(payload),
        method: method
    }
    return requestOptions;
}

export const handleAPIResponse = async (response) => {
    const responseData = await response.json();
    if (!response.ok) {
        throw new Error(responseData.message || 'An error occurred');
    }
    return responseData;
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
}

export const handleSetCookie = (token) => {
    if (token) {
        const expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
        const expires = `expires=${expiryDate.toUTCString()}`;
        document.cookie = `${ADMIN_COOKIE}=${token}; path=/; ${expires}`;
    }
}

export const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return { date: formattedDate, time: formattedTime };
};

export const convertGMTtoIST = (dateTime) => {
    const originalDate = new Date(dateTime);
    const finalDate = new Date(originalDate.getTime());
    const readableDate = finalDate.toLocaleString('en-IN', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true // for 12-hour format
    });
    return readableDate;
}