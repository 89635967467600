import { useCallback, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import UseLogout from "../../components/UseLogout/UseLogout";
import usePaginationWrapper from "../../components/PaginationWrapper/usePaginationWrapper";
import Header from "../../components/Header/Header"
import PaginationWrapper from "../../components/PaginationWrapper/PaginationWrapper";
import { fetchDrivers, fetchVehiclesLabelKeyObject } from "../../common/services/api.service";
import { DRIVER_STATUS } from "../../common/constants";
import './Drivers.scss'

const Drivers = () => {
    const { handleLogout } = UseLogout();
    const [drivers, setDrivers] = useState([])
    const [vehicleCategoryObject, setVehicleCategoryObject] = useState({});
    const { currentPage, setCurrentPage, limit, totalCount, setTotalCount } = usePaginationWrapper();

    const getDrivers = useCallback(async () => {
        try {
            const response = await fetchDrivers({ page: currentPage, limit })
            setDrivers(response?.data || [])
            setTotalCount(response?.totalCount)
        } catch (err) {
            toast.error(err?.message || 'Unable to fetch drivers')
        }
    }, [currentPage, limit, setTotalCount])

    const getVehicleCatgeory = async () => {
        const vehicleCategories = await fetchVehiclesLabelKeyObject();
        setVehicleCategoryObject(vehicleCategories?.data);
    }

    useEffect(() => {
        getVehicleCatgeory();
    }, [])

    useEffect(() => {
        getDrivers()
    }, [getDrivers])

    return (
        <main className="driver-container">
            <Toaster />
            <Header title={'Drivers'} handleLogout={handleLogout} />
            <section>
                <table className='ride-details-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Vehicle Number</th>
                            <th>Vehicle Category</th>
                            <th>Available</th>
                            <th>Admin Approval</th>
                            <th>Status</th>
                            <th>Wallet balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            drivers?.length ? drivers?.map((driver, index) => (
                                <tr key={`driver-unique-${index}`}>
                                    <td>{driver?.name}</td>
                                    <td>{driver?.phoneNumber}</td>
                                    <td>{driver?.vehicleNumber}</td>
                                    {vehicleCategoryObject && (<td>{driver?.vehicleCategory ? vehicleCategoryObject[driver?.vehicleCategory] : '-'}</td>)}
                                    <td>{driver?.available ? 'true' : 'false'}</td>
                                    <td>{driver?.adminApproval ? 'true' : 'false'}</td>
                                    <td>{DRIVER_STATUS[driver?.status]}</td>
                                    <td> {driver?.walletBalance} ₹ </td>
                                </tr>
                            )) : <p> No drivers found</p>

                        }
                    </tbody>
                </table>
                <PaginationWrapper
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    totalCount={totalCount}
                />
            </section>
        </main>
    )
}

export default Drivers