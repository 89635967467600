import React, { useCallback, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../../components/Header/Header';
import UseLogout from '../../components/UseLogout/UseLogout';
import usePaginationWrapper from '../PaginationWrapper/usePaginationWrapper';
import PaginationWrapper from '../PaginationWrapper/PaginationWrapper';
import { fetchCompletedCancelledRides } from '../../common/services/api.service';
import { RIDE_STATUS } from '../../common/constants';
import './CompletedCancelledRides.scss';

const CompletedCancelledRides = ({ title, type }) => {
    const { handleLogout } = UseLogout();
    const [rideData, setRideData] = useState([]);
    const {currentPage, setCurrentPage, limit, totalCount, setTotalCount} = usePaginationWrapper();

    const getCompletedRidesData = useCallback(async () => {
        try {
            const response = await fetchCompletedCancelledRides({ limit, page: currentPage, type});
            setRideData(response?.data || []);
            setTotalCount(response?.totalCount);
        } catch (error) {
            toast.error(error?.message || 'Failed to fetch cancelled rides');
        }
    },[currentPage, limit, setTotalCount, type]);

    useEffect(() => {
        getCompletedRidesData();
    }, [getCompletedRidesData, type]);

    return (
        <section className='completed-cancelled-rides-container'>
            <Toaster />
            <Header title={title} handleLogout={handleLogout} />
            <table className='ride-details-table'>
                <thead>
                    <tr>
                        <th>Rider</th>
                        <th>Phone Number</th>
                        <th>Pickup</th>
                        <th>Drop</th>
                        <th>Date</th>
                        <th>Vehicle</th>
                        <th>Vehicle Number</th>
                        <th>Driver</th>
                        <th>Price</th>
                        {type === RIDE_STATUS.CANCELLEDBYADMIN && <th>Status</th>}
                    </tr>
                </thead>
                <tbody>
                    {rideData?.length ? rideData?.map((ride) => {
                        return (
                            <tr key={ride?._id}>
                                <td>{ride?.riderId.name}</td>
                                <td>{ride?.riderId?.phoneNumber}</td>
                                <td>{ride?.pickup}</td>
                                <td>{ride?.drop}</td>
                                <td>{ride?.dateTime}</td>
                                <td>{ride?.vehicle}</td>
                                <td>{ride?.vehicleNumber ? ride?.vehicleNumber : '-'}</td>
                                <td>{ride?.driverId ? ride?.driverId?.name : '-'}</td>
                                <td>{ride?.price} ₹</td>
                                {type === RIDE_STATUS.CANCELLEDBYADMIN && (
                                    <td>
                                        {ride?.status === RIDE_STATUS.CANCELLEDBYADMIN
                                            ? "Cancelled by Admin"
                                            : ride?.status === RIDE_STATUS.CANCELLEDBYDRIVER
                                                ? "Cancelled by Driver"
                                                : ride?.status === RIDE_STATUS.CANCELLEDBYRIDER
                                                    ? "Cancelled by Rider"
                                                    : ""}
                                    </td>
                                )}
                            </tr>
                        );
                    }):
                    <p> No data found! </p>
                    }
                </tbody>
            </table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                totalCount={totalCount} 
            />
        </section>
    );
};

export default CompletedCancelledRides;
